<template>
  <div>
    <v-data-table
    v-model="selectedFeedback"
      :headers="headers"
      :items="allFeedback"
      fixed-header
      height="550px"
      hide-default-footer
      class="elevation-1"
      :disable-pagination="true"
      :loading="tableLoading"
      loading-text="Loading... Please wait"
      dense
      item-key="index"
      :search="search"
      show-select
      :single-select="true"
    >
      <template v-slot:top>
        <v-divider></v-divider>
        <v-toolbar flat dense>
          <v-toolbar-title>
            <span class="text-h5"> Feedback</span>
          </v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn v-if="selectedFeedback.length>0" outlined color="error" @click="updateFeedback">close</v-btn>
          <v-spacer></v-spacer>
          <v-chip class="ma-2" color="green" text-color="white">
            Total
            <v-avatar right class="green darken-4">
              {{ allFeedback.length }}
            </v-avatar>
          </v-chip>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
            style="max-width: 300px"
          ></v-text-field>
          <v-btn dark class="primary" @click="loadFeedbacks">
            <v-icon left dark> mdi-refresh </v-icon>
            Reload
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import { baseURL } from "../constant/constant";
export default {
  name: "Feedback",
  data: () => ({
    search: "",
    tableLoading: false,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
        width: 10,
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Feedback",
        value: "feedback",
      },
      {
        text: "State",
        value: "state",
      },
      {
        text: "District",
        value: "district",
      },
      {
        text: "Mandal",
        value: "mandal",
      },
      {
        text: "Village",
        value: "village",
      },
      {
        text: "Ward No.",
        value: "ward",
      },
      {
        text: "Mp constituency",
        value: "mp_constituency",
      },
      {
        text: "MLA constituency",
        value: "mla_constituency",
      },
    ],
    selectedFeedback:[]
  }),
  computed: {
    ...mapGetters(["allFeedback", "alertType", "alertMessage"]),
    ...mapState(["response"]),
  },
  mounted() {
    this.loadFeedbacks();
  },
  methods: {
    ...mapActions(["showAlert"]),
    updateFeedback(){
      axios
        .put(`${baseURL}admin/update/feedback`,null,{params: {feedback_id:this.selectedFeedback[0].id,status:'Closed'}})
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: `Updated Successfully!`,
          });
          this.selectedFeedback = [];
          this.loadFeedbacks();
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "error",
            message: `${err}`,
          });
        });
    }
  },
};
</script>
